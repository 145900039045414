export default function reviewsBlog() {
	const blogReviews = new Swiper('.rewiews-blog__slider', {
		loop: true,
		slidesPerView: 1,
		pagination: false,
		speed: 500,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},

		navigation: {
			nextEl: '.rewiews-blog__right-bnt',
			prevEl: '.rewiews-blog__left-bnt',
		},
	})
}
